import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import configData from './../../config/constant.json'


import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const FileviewerDialog = (props) => {
    let [pending, setPending] = useState(true);
    let [noPreview, setNoPreview] = useState(false);

    //Viewers
    let [imageViewer, setImageViewer] = useState(false);
    let [videoViewer, setVideoViewer] = useState(false);
    let [pdfviewer, setPdfViewer] = useState(false);
    let [audioViewer, setAudioViewer] = useState(false);
    
    useEffect(async () => {
        let videoMimes = ['video/mp4', 'video/ogg']
        let imageMimes = ['image/apng', 'image/bmp', 'image/gif', 'image/x-icon', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/tiff', 'image/webp']
        let pdfMimes = ['application/pdf']
        let audioMimes = ['audio/wav', 'audio/mpeg', 'audio/aac', 'audio/aacp', 'audio/ogg', 'audio/webm']

        const response = await fetch(`${configData.SERVER_URL}/fileBrowser/downloadFile?file=${props.assetDetials._id}`, { credentials: configData.COOKIES_CONFIG });
        const blob = await response.blob();
        const url = await URL.createObjectURL(blob);
        setPending(false)
        if (imageMimes.includes(props.assetDetials.mimeType)) {
            setImageViewer(url)
        }
        else if (videoMimes.includes(props.assetDetials.mimeType)) {
            setVideoViewer(url)
        }
        else if (pdfMimes.includes(props.assetDetials.mimeType)) {
            setPdfViewer(url)
        }
        else if (audioMimes.includes(props.assetDetials.mimeType)) {
            setAudioViewer(url)
        }
        else {
            setNoPreview(true);
        }

    }, [])

    // Get the blob url
    return (

        <>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={props.show}
                onHide={props.onHide}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        { props.assetDetials.originalName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: "center" }} >
                    {
                        pending && <p>Loading...</p>
                    }
                    {
                        videoViewer &&
                        <video style={{ width: "100%", height: "100%" }} controls >
                            <source src={videoViewer} />
                            Your browser does not support the video tag.
                        </video>
                    }
                    {
                        imageViewer &&
                        <img style={{ width: "100%", height: "100%" }} src={imageViewer}></img>
                    }
                    {
                        pdfviewer &&

                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                            <Viewer fileUrl={pdfviewer} />
                        </Worker>
                    }
                    {
                        audioViewer &&
                        <audio controls>
                            <source src={audioViewer} />
                            Your browser does not support the audio.
                        </audio>
                    }
                    {
                        noPreview &&
                        <>
                            <h4>No Preview Available</h4>
                            <p>Coming Soon in Next Update!!</p>
                        </>

                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default FileviewerDialog;