import React from 'react';
const Profile = () => {

    return (
        <> 
        {/* <h1 style={{textAlign:'center'}} >User Profile</h1>
        
        <div className='profilehead' style={{textAlign:'center',marginTop:'50px'}} >
         <FontAwesomeIcon  icon={faUser} style={{textAlign:'center', fontSize:'80px',borderStyle:'dashed',borderRadius:'50%',height:'80px',width:'80px'}} /> 
         <img src="img_girl.jpg"></img>
         </div>
        <div  style={{textAlign:'center' ,marginTop:'50px'}}>
          <h1>Yuvraj Yadav</h1>
          <p >CEO  Founder, Example</p>
         <div><p>Used Space :</p><p>space remaining :</p></div> 
          <p><button>Change Password</button></p>
          <p><button>upgrade profile</button></p>
         
        </div> */}
        <h4>Profile in progess</h4>
        
        </>
    
        
    )
}

  export default Profile;