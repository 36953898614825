import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import configData from "./../config/constant.json";
const Login = () => {
  let history = useHistory();
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");

  const loginUser = (e) => {
    e.preventDefault();
    let user = { email, password };

    fetch(`${configData.SERVER_URL}/auth/login`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(user),
      credentials: configData.COOKIES_CONFIG,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == false) {
          alert("Email or Password Incorrect");
        } else {
          alert("Login Succesful");
          history.go("/pages/home");
        }
      })
      .catch((err) => alert("Something went Wrong"));
  };

  return (
    <form onSubmit={loginUser}>
      <h3>Sign In</h3>
      <div className="form-group">
        <label>
          Email address <span style={{ color: "red" }}>*</span>
        </label>
        <input
          type="email"
          className="form-control"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label>
          Password <span style={{ color: "red" }}>*</span>
        </label>
        <input
          type="password"
          className="form-control"
          placeholder="Enter password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheck1"
          />
          <label className="custom-control-label" htmlFor="customCheck1">
            Remember me
          </label>
        </div>
      </div>
      <button type="submit" className="btn btn-primary btn-block">
        Submit
      </button>
      <div className="forgot-password text-right">
        <p>
          <Link to="/auth/forgetPassword">Forgot password?</Link>
        </p>
        <br />
        Create Account <Link to="/auth/register">sign up?</Link>
      </div>
    </form>
  );
};

export default Login;
