import { Modal, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import configData from './../../config/constant.json'

const PropertiesDialog = (props) => {
    let [pending, setPending] = useState(true);
    let [error, setError] = useState(false);
    let [showProperties, setShowProperties] = useState(false);
    let [propertiesData, setPropertiesData] = useState('');


    // Call the initial route to get the folder and file properties

    useEffect(() => {
            const abortCont = new AbortController();
            fetch(`${configData.SERVER_URL}/filebrowser/getproperties?id=${props.assetid}&type=${props.assetType}`, { credentials: configData.COOKIES_CONFIG, signal: abortCont.signal })
                .then(res => {
                    if (!res.ok) {
                        throw Error('Something Went Wrong')
                    }
                    return res.json()
                })
                .then(res_data => {
                    if (res_data.status === false) {
                        throw Error(res_data.message)
                    } else {
                        setPropertiesData(res_data.data)
                        setPending(false);
                        setShowProperties(true);
                    }
                })
                .catch(err => {
                    if (abortCont === "AbortError") {
                    } else {
                        setPending(false);
                        setError(err.message);
                    }

                })


            return () => abortCont.abort();
     
    }, [])


    return (
        <>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={props.show}
                onHide={props.onHide}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Properties
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        showProperties &&
                        <div style={{ text: 'bold' }}>
                            <div>Name : {propertiesData.name}</div>
                            <div>uploaded by : {propertiesData.uploadedBy}</div>
                            <div>created on : {propertiesData.createdOn}</div>
                            <div>Last  modified : {propertiesData.lastModified}</div>
                            <div>Size : {propertiesData.size}</div>
                        </div>
                    }
                    {
                        pending && <p>Pending...</p>
                    }
                    {
                        error && <p>{error}</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default PropertiesDialog;
