import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AdminHome from "./AdminHome";
import AdminProfile from "./AdminProfile";
import AdminNotFound from "./components/AdminNotFound";
import Users from "./components/Users";
import AdminNavBar from "./AdminNavBar";


const AdminPage = () => {
    return (
        <Router>
            <AdminNavBar />
            <br />
            <Route
                path="/admin"
                render={({ match: { url } }) =>
                (
                    <Switch>
                        <Route path={`${url}/`} component={AdminHome} exact />
                        <Route path={`${url}/home`} component={AdminHome} exact />
                        <Route path={`${url}/profile`} component={AdminProfile} />
                        <Route path={`${url}/users`} component={Users} />
                        <Route path={`${url}/*`} component={AdminNotFound} />
                    </Switch>
                )
                }
            />
        </Router>
    );
}

export default AdminPage;