import { useEffect, useState } from 'react';
import { Button, Row, Col, Form, Modal } from 'react-bootstrap';
import configData from './../../config/constant.json'

const TransferDialog = (props) => {
  let [transferDiv, showtransferDiv] = useState(false)
  let [deleteDiv, showDeleteDiv] = useState(false)
  let [confirmDiv, showConfirmDiv] = useState(true);
  let [error, setError] = useState(false);
  let [pending, setPending] = useState(false);
  
  const userDetails = props.allUserdata
  const [radio, setradio] = useState(false)
  let dropdownvalue = userDetails[0]

  function dropDown(value) {
    dropdownvalue = value;
  }

  function handleDelete() {
    const abortCont = new AbortController();

    let data = {
      id: dropdownvalue,
      target: props.currentUser._id,
      radio: radio
    }

    fetch(`${configData.SERVER_URL}/admin/deleteuser`, {
      credentials: configData.COOKIES_CONFIG,
      signal: abortCont.signal,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then(res => {
        if (!res.ok) {
          throw Error('Something Went Wrong')
        }
        return res.json()
      })
      .then(res_data => {
        if (res_data.status == false) {
          throw Error(res_data.message)
        } else {
          setPending(false);
     
          props.onHide(true);
        }
      })
      .catch(err => {
        if (abortCont === "AbortError") {
        } else {
          setError(err.message);
          setPending(false);
        }
      })
  }

  function handleYes() {
    showConfirmDiv(false);
    showDeleteDiv(true);
  }


  function handleTransfer(bool) {
    showtransferDiv(bool)
    setradio(bool)
  }

  return (

    <div>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.show}
        onHide={props.onHide}
      >

        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            confirmDiv
            &&
            <>
              <h4>Do you really want to delete ??</h4>
            </>
          }
          {
            deleteDiv &&
            <div>
              <p>Do you want to transfer user data to another user??</p>
              <input type="radio" value={radio} name="transferRadio" onChange={() => handleTransfer(true)} />
              &nbsp;<label >Yes</label> &nbsp;&nbsp;&nbsp;&nbsp;
              <input type="radio" value={radio} name="transferRadio" onChange={() => handleTransfer(false)} />
              &nbsp;<label >No</label><br />
            </div>
          }
          {
            transferDiv &&
            <Form>
              <Form.Group className="mb-3">
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control disabled id="disabledTextInput"  value={props.currentUser.name} />
                    </Form.Group>
                  </Col>
                  <Col>

                    <Form.Select onChange={(e) => dropDown(e.target.value)}>
                      {userDetails.map((option) => (
                        <option key={option._id}  value={option._id}>{option.name}</option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Text className="text-muted">

              </Form.Text>
            </Form>

          }
          {
            pending && <p>Pending...</p>
          }
          {
            error && <p>{error}</p>
          }
          
        </Modal.Body>
        <Modal.Footer>
          {
            confirmDiv &&
            <>
              <Button onClick={handleYes}>Yes</Button>
              <Button onClick={props.onHide}>No</Button>
            </>
          }
          {
            deleteDiv &&
            <>
              <Button onClick={props.onHide}>Cancel</Button>
              <Button onClick={handleDelete}>Delete</Button>
            </>
          }
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default TransferDialog;