import { useEffect,useState} from "react";
import { Table, Button } from 'react-bootstrap'
import TransferDialog from "./TransferDialog";
import configData from './../../config/constant.json'
import EditDialogBox from "./EditDialogBox"

const Users = () => {
    let [transferDialog, setTransferDialog] = useState(false);
    let [editDialog, setEditDialog] = useState(false);
    let [userDetails, setUserDetails] = useState([]);
    let [filterDetails, setFilterDetails] = useState([]);
    let [selectedUser, setSelectedUser] = useState({});

    const callBackEdit = (users) => {
        setSelectedUser(users);
        setEditDialog(true)    
    }

    const callBackTrans = (users) => {
        setSelectedUser(users);
        let filterData =  userDetails.filter(item=> item._id != users._id )
        setFilterDetails(filterData)
        setTransferDialog(true)
    }

    useEffect(() => {
        const abortCont = new AbortController();
        fetch(`${configData.SERVER_URL}/admin/getUsersDetails`, { credentials: configData.COOKIES_CONFIG, signal: abortCont.signal })
            .then(res => {
                if (!res.ok) {
                    throw Error('Something Went Wrong')
                }
                return res.json()
            })
            .then(res_data => {
                if (res_data.status == false) {
                    throw Error(res_data.message)
                } else {
                    setUserDetails(res_data.usersDetails)
                }
            })
            .catch(err => {
                if (abortCont === "AbortError") {
                } else {
                    alert(err.message);
                }
            })


        return () => abortCont.abort();
    }, [editDialog, transferDialog])
    
    return (
        <>
            <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Sr. No</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Space Used</th>
                                <th className="text-center">Action</th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                userDetails.map((item, index) => (
                                    <tr key={item._id} >
                                        <td>{index + 1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.email}</td>
                                        <td>{item.user_role}</td>
                                        <td>{item.used_space}/{item.allocated_space}</td>
                                        <td className="text-center"><Button onClick={() => callBackEdit(item)} variant="outline-primary">Edit</Button> &nbsp;&nbsp;
                                            {editDialog && <EditDialogBox currentUser={selectedUser} show={editDialog} onHide={() => setEditDialog(false)} ></EditDialogBox>}
                                            <Button onClick={() => callBackTrans(item)} variant="outline-primary">Delete</Button>
                                            {transferDialog && <TransferDialog currentUser={selectedUser} show={transferDialog} allUserdata={filterDetails}  onHide={() => setTransferDialog(false)} ></TransferDialog>}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </div>
            </div>

        </>

    );
}

export default Users;