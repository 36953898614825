import { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import configData from './../../config/constant.json'

const RenameDialog = (props) => {
    let [newName, setNewName] = useState('');
    let [renameForm, setRenameForm] = useState(true)
    let [renameDone, setRenameDone] = useState(false)
    let [error, setError] = useState(false);
    let [pending, setPending] = useState(false);

    const assetDetials = props.assetDetials
    const assetType = props.assetType
    const updateList = props.updateList

    function handleSave() {
        setPending(true);
        setRenameForm(false);

        if (newName.length == 0) {
           
        } else {
            let data = { newName, assetId: assetDetials._id, assetType}
            if (assetType == 'file') {
                let fileName = newName.split('.');
                fileName = fileName[0] + "." + assetDetials.extension
                data.newName = fileName
            }

            // Call the Route
            const abortCont = new AbortController();
            fetch(`${configData.SERVER_URL}/fileBrowser/rename`, {
                credentials: configData.COOKIES_CONFIG,
                signal: abortCont.signal,
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            })
                .then(res => {
                    if (!res.ok) {
                        throw Error('Something Went Wrong')
                    }
                    return res.json()
                })
                .then(res_data => {
                    if (res_data.status == false) {
                        throw Error(res_data.message)
                    } else {
                       setPending(false);
                       setRenameDone(true);

                       // callback function to update in parent component
                       updateList(assetDetials._id, data.newName)
                    }
                })
                .catch(err => {
                    if (abortCont === "AbortError") {
                    } else {
                        setPending(false);
                        setError(err.message);
                    }

                })


            return () => abortCont.abort();
        }
    }

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Rename
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    renameForm &&
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Control type="text" placeholder="New Name" onChange={(e) => setNewName(e.target.value)} required />
                        </Form.Group>
                        <Form.Text className="text-muted">
                                    Avoid dots in filename!! eg. xyz.q
                        </Form.Text>
                    </Form>
                }
                {
                    pending && <p>Pending...</p>
                }
                {
                    error && <p>{error}</p>
                }
                {
                    renameDone && <p>Rename Done</p>
                }
            </Modal.Body>
            <Modal.Footer>
                {renameForm && <Button onClick={handleSave}>Save</Button>}
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default RenameDialog;