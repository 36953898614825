import { BrowserRouter as Router, Switch, Route, Link, Routes } from "react-router-dom";
import Login from './Login'
import Register from "./Register";
import ForgetPassword from "./ForgetPassword";
import { isAuthenticated } from "../utils/auth_service";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import NotFound from "../pages/NotFound";

const Auth = () => {
    if (!isAuthenticated()) {
        return (
            <Router>
                <div className="App">
                    <nav className="navbar navbar-expand-lg navbar-light fixed-top">
                        <div className="container">
                            <Link className="navbar-brand" to={"/auth/login"}>EduDrive</Link>
                            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item">
                                        <Link className="nav-link" to={"/auth/login"}>Login</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={"/auth/register"}>Sign up</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    <div className="auth-wrapper">
                        <div className="auth-inner">
                            <Route
                                //Check here if authenticated, if redirect him to the dashboard
                                path="/auth"
                                render={({ match: { url } }) => (
                                    <Switch>
                                        <Route path={`${url}/`} component={Login} exact />
                                        <Route path={`${url}/login`} component={Login} />
                                        <Route path={`${url}/register`} component={Register} />
                                        <Route path={`${url}/forgetPassword`} component={ForgetPassword}/>
                                        <Route path={`${url}/*`} component={NotFound} />
                                    </Switch>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </Router>
        );
    }
    else {
        return <Redirect to='/pages/home'></Redirect>
    }
}

export default Auth;