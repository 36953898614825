import Cookies from 'js-cookie'
import configData from './../config/constant.json'

let isAuthenticated = () => {
    let jwt = Cookies.get('jwt')
    if(jwt!==undefined){
       // Call Route and check if Jwt is proper
       const abortCont = new AbortController();
       fetch(`${configData.SERVER_URL}/auth/verify`, { credentials: configData.COOKIES_CONFIG, signal: abortCont.signal})
           .then(res => {
               if (!res.ok) {
                   throw Error('Token validation failed')
               }
               return res.json()
           })
           .then(res_data => {
               if (res_data.status == false) {
                   throw Error('Token validation failed')
               } else {
                    console.log(res_data)
               }
           })
           .catch(err => {
               if (abortCont === "AbortError") {
               } else {
                   return false;
               }

           })
       return () => abortCont.abort();
    }
    return false;
}

let logoutUser = () => {
    Cookies.remove('jwt',{ path: '/', domain: configData.DOMAIN  })
}   


export {isAuthenticated, logoutUser};