import {Link} from 'react-router-dom'
import { isAuthenticated } from '../utils/auth_service';
const NotFound = () => {

    if(isAuthenticated()){
        return ( 
            <div style={{alignItems: "center"}}>
                        <h1 style={{color: "red",paddingLeft:"45%"}} className="title">404</h1>
    
                        Page Not Found. Go to <Link to='/pages/home'>Home</Link>
            </div>
            
         );
    }
    else if(!isAuthenticated()){
        return ( 

                <div style={{alignContent: "center"}}>
                            <h1 style={{color: "red"}} className="title">404</h1>
        
                            Page Not Found. Go to  <Link to='/auth/login/'>Login</Link>
                </div>
         );
    }
}
 
export default NotFound;