import { useState } from 'react';
import { Button, Modal, } from "react-bootstrap";
import configData from './../../config/constant.json'
const EditDialogBox = (props) => {

    let [allocatedSize, setAllocatedSize] = useState(props.currentUser.allocated_space);
    let [error, setError] = useState(false);
    let [pending, setPending] = useState(false);
    
    const handleUpdate = () => {
        let updated = allocatedSize.split(' ')
        let size = parseInt(updated[0]) * 1024 * 1024 * 1024
        let data = {
            user_id: props.currentUser._id,
            allocatedSize: size,
        }
        const abortCont = new AbortController();
        fetch(`${configData.SERVER_URL}/admin/updateUserDetails`, {
            credentials: configData.COOKIES_CONFIG,
            signal: abortCont.signal,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        })
            .then(res => {
                if (!res.ok) {
                    throw Error('Something Went Wrong')
                }
                return res.json()
            })
            .then(res_data => {
                if (res_data.status == false) {
                    throw Error(res_data.message)
                } else {
                    props.onHide()
                }
            })
            .catch(err => {
                if (abortCont === "AbortError") {
                } else {
                    setError(err.message);
                    setPending(false);
                }

            })


        return () => abortCont.abort();
    }


    return (
        <>
            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={props.show}
                onHide={props.onHide}>

                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Allocated Space
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-md'>
                            <label  >allocated space</label>
                            <input className="form-control" value={allocatedSize} onChange={(e) => { setAllocatedSize(e.target.value) }} placeholder="Enter Size" />
                        </div>
                    </div>
                    {
                        pending && <p>Pending...</p>
                    }
                    {
                        error && <p>{error}</p>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleUpdate}  >Save</Button>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EditDialogBox;