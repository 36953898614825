import {Link} from 'react-router-dom'
const AdminNotFound = () => {
    return (

        <div style={{ alignItems: "center" }}>
            <h1 style={{ color: "red", paddingLeft: "45%" }} className="title">404</h1>

            Page Not Found. Go to <Link to='/admin/home'>Home</Link>
        </div>


    );
}

export default AdminNotFound;