import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import configData from "./../config/constant.json";
import { useHistory } from "react-router-dom";

const ForgetPassword = () => {
  let history = useHistory();

  let [emailForm, setEmailForm] = useState(true);
  let [otpForm, setOtpForm] = useState(false);
  let [passwordForm, setPasswordForm] = useState(false);

  let [errMessage, setErrMessage] = useState("");

  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [confirmPassword, setConfirmPassword] = useState("");
  let [otp, setOtp] = useState("");

  const [counter, setCounter] = useState(false);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const forgotPassword = (e) => {
    e.preventDefault();
    if (email.length < 4) return alert("Please give a email");
    setErrMessage(false);
    let data = { email };
    fetch(`${configData.SERVER_URL}/auth/forgetpassword`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      credentials: configData.COOKIES_CONFIG,
    })
      .then((response) => {
        if (!response.ok) throw Error("Something went wrong");
        return response.json();
      })
      .then((data) => {
        if (data.status === false) {
          setErrMessage(data.message);
          setOtpForm(false);
        } else {
          setOtpForm(true);
          setEmailForm(false);
          setCounter(59);
        }
      })
      .catch((err) => {
        setErrMessage(err);
      });
  };

  // Verify Otp
  const verifyOtp = (e) => {
    e.preventDefault();
    let data = { email, otp };
    setErrMessage(false);

    fetch(`${configData.SERVER_URL}/auth/verifyotp`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      credentials: configData.COOKIES_CONFIG,
    })
      .then((response) => {
        if (!response.ok) throw Error("Something went wrong");
        return response.json();
      })
      .then((data) => {
        if (data.status === false) {
          throw Error(data.message);
        } else {
          setOtpForm(false);
          setEmailForm(false);
          setPasswordForm(true);
        }
      })
      .catch((err) => setErrMessage(err));
  };

  // Change Password
  const changePassword = (e) => {
    e.preventDefault();
    let data = { email, password };
    setErrMessage(false);
    if (password === confirmPassword) {
      fetch(`${configData.SERVER_URL}/auth/changepassword`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
        credentials: configData.COOKIES_CONFIG,
      })
        .then((response) => {
          if (!response.ok) throw Error("Something went wrong");
          return response.json();
        })
        .then((data) => {
          if (data.status === false) {
            throw Error(data.message);
          } else if (data.status === true) {
            history.push("/auth/login");
            alert("password changed sucessfully");
          }
        })
        .catch((err) => setErrMessage(err));
    } else {
      setErrMessage("Password Mismatch");
    }
  };

  return (
    <>
      {emailForm && (
        <div>
          <h6 className="password text-center">Enter the Email :</h6>
          <div className="form-group text-center">
            <input
              size="40"
              type="email"
              className="inputEmail"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <br />
            <span style={{ color: "red" }}>{errMessage}</span>
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-block text-center"
            onClick={forgotPassword}
          >
            Submit
          </button>
          <p className="forgot-password text-right">
            Already registered <Link to="/auth/login">sign in?</Link>
          </p>
        </div>
      )}
      {otpForm && (
        <form>
          <h6 className="password text-center">Enter the 6 Digit Otp :</h6>
          <div className="form-group text-center">
            <input
              size="40"
              type="otp"
              placeholder=""
              className="inputOtp"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
            />

            <span style={{ color: "red" }}>{errMessage}</span>
          </div>

          <div className="row">
            <p>Otp has been to your registered email</p>
            <div className="col-md-4">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={counter !== 0}
                onClick={forgotPassword}
              >
                Resend
              </button>
              {counter}
            </div>
            <div className="col-md-5"></div>
            <div className="col-md-3">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                onClick={verifyOtp}
              >
                Verify
              </button>
            </div>
          </div>

          <p className="forgot-password text-right">
            Already registered <Link to="/auth/login">sign in?</Link>
          </p>
        </form>
      )}
      {passwordForm && (
        <form>
          <div>
            <h3>Change your Password</h3>
          </div>
          <h6 className="password text-left">Enter the New Password :</h6>
          <div className="form-group">
            <input
              size="40"
              type="Password"
              className="inputPassword"
              placeholder=""
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <br />
          <h6 className="password text-left">Confirm New Password :</h6>
          <div className="form-group">
            <input
              size="40"
              type="Password"
              className="inputPassword"
              placeholder=""
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <br />
            <span style={{ color: "red" }}>{errMessage}</span>
          </div>
          <br />
          <button
            type="submit"
            className="btn btn-primary btn-block  "
            onClick={changePassword}
          >
            Set New Password
          </button>
          <p className="forgot-password text-right">
            Already registered <Link to="/auth/login">sign in?</Link>
          </p>
        </form>
      )}
    </>
  );
};

export default ForgetPassword;
